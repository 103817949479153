var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mt-2 mb-3",
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.botaoFiltrar.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-6 mt-3 pt-3" },
            [
              _c("v-col", [
                _c(
                  "span",
                  {
                    staticClass: "text-left font-weight-black display-1 pl-0",
                    style: { color: _vm.primaryColorFont }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-6" },
            [
              _c("v-col", { attrs: { cols: "12", sm: "12", md: "12" } }, [
                _c(
                  "div",
                  { staticClass: "ghost-card" },
                  [
                    _c("div", { staticClass: "mb-5" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("Inserir Títulos")
                      ])
                    ]),
                    _c("v-textarea", {
                      attrs: {
                        solo: "",
                        small: "",
                        label: _vm.textAreaLabel,
                        hint: _vm.textAreaHint,
                        placeholder: _vm.textAreaPlaceholder
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "v-icon--clearable",
                                  on: { click: _vm.limparCampo }
                                },
                                [_vm._v(" mdi-close-circle ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.filtros.ds_nosso_numero,
                        callback: function($$v) {
                          _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                        },
                        expression: "filtros.ds_nosso_numero"
                      }
                    }),
                    _c("div", { staticClass: "mb-5" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("Upload de Arquivo")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("v-file-input", {
                          key: _vm.counterRender,
                          ref: "myFileInput",
                          staticClass: "mb-n7 mr-2 white--text",
                          attrs: {
                            "prepend-icon": "",
                            accept:
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                            label: "Escolher arquivo ",
                            "show-size": "",
                            chips: "",
                            dense: "",
                            solo: "",
                            id: "fileInput",
                            name: "file"
                          },
                          on: { change: _vm.uploadFileReference },
                          model: {
                            value: _vm.file,
                            callback: function($$v) {
                              _vm.file = $$v
                            },
                            expression: "file"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-capitalize mr-2",
                            on: {
                              click: function($event) {
                                return _vm.openUpload()
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "ma-2 text-left" }, [
                              _vm._v(" mdi-upload ")
                            ]),
                            _vm._v(" upload ")
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-capitalize",
                            attrs: { href: _vm.link_modelo }
                          },
                          [
                            _c("v-icon", { staticClass: "ma-2 text-left" }, [
                              _vm._v(" mdi-download ")
                            ]),
                            _vm._v(" Baixar Modelo ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "my-5" }, [
                      _c("span", { staticClass: "sub-title" }, [
                        _vm._v(
                          " Formatos compatíveis: .xls/.xlsx/.xlsm. Limite de tamanho: 60mb "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-6" },
            [
              _c("v-col", [
                _c("div", { staticClass: "card-tip" }, [
                  _c(
                    "span",
                    { staticClass: "title" },
                    [_c("v-icon", [_vm._v(" mdi-information-outline ")])],
                    1
                  ),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v(" " + _vm._s(_vm.dica) + " ")
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-6" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass: "grey lighten-4",
                              attrs: { small: "" }
                            },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "font-weight-light black--text"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "primary--text lighten-2" },
                                    [_vm._v(" mdi-filter ")]
                                  ),
                                  _vm._v(" Aplicar Filtros ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                { staticClass: "my-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex flex-row",
                                      attrs: {
                                        cols: "12",
                                        xs: "12",
                                        sm: "12",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: [
                                              "###.###.###-##",
                                              "XX.XXX.XXX/XXXX-##"
                                            ],
                                            expression:
                                              "[\n                                            '###.###.###-##',\n                                            'XX.XXX.XXX/XXXX-##'\n                                        ]"
                                          }
                                        ],
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          label: "CPF/CNPJ",
                                          hint: "Informe o Número do CPF/CNPJ",
                                          placeholder: "Ex.:000.000.000-00",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.filtros
                                              .nu_identificacao_devedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "nu_identificacao_devedor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            filtros.nu_identificacao_devedor\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        xs: "12",
                                        sm: "12",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          label: "Nome",
                                          hint:
                                            "Informe o nome para efetuar a busca",
                                          placeholder: "Ex.: José",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filtros.nm_devedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "nm_devedor",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.nm_devedor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        xs: "12",
                                        sm: "12",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          items: _vm.listaUfs,
                                          label: "UF",
                                          "return-object": true
                                        },
                                        model: {
                                          value: _vm.filtros.cd_uf_devedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "cd_uf_devedor",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.cd_uf_devedor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  !_vm.isUsuarioAgrupador
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            xs: "12",
                                            sm: "12",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              solo: "",
                                              dense: "",
                                              disabled:
                                                _vm.loadingAgrupador ||
                                                _vm.usuarioAgrupador != null,
                                              loading: _vm.loadingAgrupador,
                                              items: _vm.agrupadores,
                                              label: "Agrupador",
                                              "item-text": "nm_agrupador",
                                              "item-value": "ds_agrupador",
                                              "data-cy": "agrupador"
                                            },
                                            model: {
                                              value: _vm.filtros.ds_agrupador,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "ds_agrupador",
                                                  $$v
                                                )
                                              },
                                              expression: "filtros.ds_agrupador"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mx-0 mt-2 mb-0 pa-0" },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                {
                  staticClass: "pr-9",
                  attrs: {
                    cols: "10",
                    xs: "6",
                    sm: "6",
                    md: "3",
                    lg: "3",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: {
                        block: "",
                        color: "primary",
                        loading: _vm.loadingFiltros
                      },
                      on: { click: _vm.botaoFiltrar }
                    },
                    [
                      _vm._v(" Filtrar "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("search")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800px",
            persistent: "",
            scrollable: "",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogError,
            callback: function($$v) {
              _vm.dialogError = $$v
            },
            expression: "dialogError"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dark: "", color: "error" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogError = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Erro")])
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "font-weight-black" },
                            [
                              _c("v-icon", { staticClass: "ma-1" }, [
                                _vm._v("mdi-alert")
                              ]),
                              _vm._v(" Erro ao enviar a arquivo de upload ")
                            ],
                            1
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Houve um erro ao ler o arquivo de upload. Por favor, tente novamente. "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          _vm.dialogError = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")]), _vm._v("Fechar ")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }